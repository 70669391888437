
        <template>
          <div class="vc-snippet-doc">
            <h2>UserDepRole 组织架构</h2>
<p>巨树：基于ztree封装的Vue树形组件，轻松实现海量数据的高性能渲染。
支持同时渲染多个树。</p>
<h3>基础用法</h3>
<p>基础的用法。</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-0 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">ixu-user-dep-role</span>
  <span class="hljs-attr">:open.sync</span>=<span class="hljs-string">"showDialog"</span>
  <span class="hljs-attr">:tab</span>=<span class="hljs-string">"['dep']"</span>
  <span class="hljs-attr">:depHandle</span>=<span class="hljs-string">"1"</span>
  <span class="hljs-attr">:multiple</span>=<span class="hljs-string">"choice == 0"</span>
  <span class="hljs-attr">:dep2user</span>=<span class="hljs-string">"choice == 0"</span>
  <span class="hljs-attr">:choice</span>=<span class="hljs-string">"readRangeList"</span>
  @<span class="hljs-attr">save</span>=<span class="hljs-string">"saveReadRangeHandle"</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-user-dep-role</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
        <span class="hljs-keyword">return</span> {
            <span class="hljs-attr">showDialog</span>:<span class="hljs-literal">true</span>,
            <span class="hljs-attr">choice</span>: <span class="hljs-number">0</span>,
            <span class="hljs-attr">readRangeList</span>:[]
        }
    },
    <span class="hljs-attr">methods</span>: {
        saveReadRangeHandle(evt, treeId, treeNode) {
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'saveReadRangeHandle'</span>, evt, treeId, treeNode)
        },
    }
}
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>setting</td>
<td>ztree 配置</td>
<td>Object</td>
<td>—</td>
<td><code>{view: {showIcon: false}}</code></td>
</tr>
<tr>
<td>nodes</td>
<td>ztree 数据</td>
<td>Array</td>
<td>—</td>
<td><code>[]</code></td>
</tr>
</tbody>
</table>

          </div>
        </template>
        <script>
           //
//
//
//
//
//
//
//
//
//
//
//
           export default {
           name: 'vc-component-doc',
           components: {
            "vc-snippent-0":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ixu-user-dep-role',{attrs:{"open":_vm.showDialog,"tab":['dep'],"depHandle":1,"multiple":_vm.choice == 0,"dep2user":_vm.choice == 0,"choice":_vm.readRangeList},on:{"update:open":function($event){_vm.showDialog=$event},"save":_vm.saveReadRangeHandle}})}
var staticRenderFns = []

          const exportJavaScript =  {
    data() {
        return {
            showDialog:true,
            choice: 0,
            readRangeList:[]
        }
    },
    methods: {
        saveReadRangeHandle(evt, treeId, treeNode) {
            console.log('saveReadRangeHandle', evt, treeId, treeNode)
        },
    }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })()
           }
         }
       </script>
       <style lang='scss'>
         
       </style>